
const
    LOGGED_IN="LOGGED_IN",
    SAVE_TOKEN="SAVE_TOKEN",
    SAVE_NAVIGATION_MODULE="SAVE_NAVIGATION_MODULE",
    SAVE_NAVIGATION_MENU="SAVE_NAVIGATION_MENU",
    SAVE_NAVIGATIONS="SAVE_NAVIGATIONS",
    DATABASE_FILTER_CHANGE_EMP = "DATABASE_FILTER_CHANGE_EMP",
    DATABASE_FILTER_CHANGE_PROVINCE="DATABASE_FILTER_CHANGE_PROVINCE",
    DATABASE_FILTER_CHANGE_CITY="DATABASE_FILTER_CHANGE_CITY",
    DATABASE_FILTER_CHANGE_MUNICIPALITY="DATABASE_FILTER_CHANGE_MUNICIPALITY",
    DATABASE_FILTER_CHANGE_COMPANY = "DATABASE_FILTER_CHANGE_COMPANY",
    DATABASE_FILTER_CHANGE_CONTRACT = "DATABASE_FILTER_CHANGE_CONTRACT";

export{
    LOGGED_IN,
    SAVE_TOKEN,
    SAVE_NAVIGATION_MODULE,
    SAVE_NAVIGATION_MENU,
    SAVE_NAVIGATIONS,
    DATABASE_FILTER_CHANGE_EMP,
    DATABASE_FILTER_CHANGE_PROVINCE,
    DATABASE_FILTER_CHANGE_CITY,
    DATABASE_FILTER_CHANGE_MUNICIPALITY,
    DATABASE_FILTER_CHANGE_COMPANY,
    DATABASE_FILTER_CHANGE_CONTRACT
}




