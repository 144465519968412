import   {
    DATABASE_FILTER_CHANGE_EMP,
    DATABASE_FILTER_CHANGE_PROVINCE,
    DATABASE_FILTER_CHANGE_CITY,
    DATABASE_FILTER_CHANGE_MUNICIPALITY,
    DATABASE_FILTER_CHANGE_COMPANY,
    DATABASE_FILTER_CHANGE_CONTRACT
} from '../constant';
const initial ={
    CurrentEmp:null,
    Province:null,
    City:null,
    Municipality:null,
    Company:null,
    Contract:null
}


const reduceDatabaseView= (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case DATABASE_FILTER_CHANGE_EMP:
            newState.CurrentEmp = action.payload;
            return Object.assign({}, newState);
        case DATABASE_FILTER_CHANGE_PROVINCE:
            newState.Province = action.payload;
            return Object.assign({}, newState);
        case DATABASE_FILTER_CHANGE_CITY:
            newState.City = action.payload;
            return Object.assign({}, newState);
        case DATABASE_FILTER_CHANGE_MUNICIPALITY:
            newState.Municipality = action.payload;
            return Object.assign({}, newState);

        case DATABASE_FILTER_CHANGE_COMPANY:
            newState.Company = action.payload;
            return Object.assign({}, newState);
        case DATABASE_FILTER_CHANGE_CONTRACT:
            newState.Contract = action.payload;
            return Object.assign({}, newState);

        default:
            return state
    }
}

export default reduceDatabaseView;
