import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../../component/common/ef-pageContent';
import { DATABASE_FILTER_CHANGE_EMP,
    DATABASE_FILTER_CHANGE_PROVINCE,
    DATABASE_FILTER_CHANGE_MUNICIPALITY,
    DATABASE_FILTER_CHANGE_COMPANY,
    DATABASE_FILTER_CHANGE_CONTRACT} from "../../reducers/constant";



import FilteringCompent from './filtering';
import Database from './database';

export default class HomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: "",
            municipality: "",
            company: "",
            contract: "",
            Filter: null,
        }

    }

    componentDidMount() {

    }

    clearNewFilter=()=>{
        this.setState({
            Filter:null
        })
    }

    handleInput = (e, key) => {
        this.setState({[key]: e.target.value})
        this.saveToStore(key,e.target.value);
    }

    handleInputData = (data, key) => {
        this.setState({[key]: data});
        this.saveToStore(key,data);
    }
    saveToStore=(key,val)=>{

        let action  = "";
        if(key ==="province"){
            action=DATABASE_FILTER_CHANGE_PROVINCE;
        }
        if(key ==="municipality"){
            action=DATABASE_FILTER_CHANGE_MUNICIPALITY;
        }
        if(key ==="company"){
            action=DATABASE_FILTER_CHANGE_COMPANY;
        }
        if(key ==="contract"){
            action=DATABASE_FILTER_CHANGE_CONTRACT;
        }


        this.props.dispatch({
            type: action,
            payload: val
        });

}


    render() {
        return (


            <PageContentWallpaper {...this.props} >
                <div className="wrapper">
                    <div className="col-xs-12">
                        {this.state.Filter ===null&&(
                        <FilteringCompent
                            state={this.state}
                            handleInput={this.handleInput}
                            handleInputData={this.handleInputData}
                        />
                        )}

                        {this.state.Filter !==null&&(
                            <Database
                                state={this.state}
                                clearNewFilter={this.clearNewFilter}
                                handleInput={this.handleInput}
                            />
                        )}
                    </div>
                </div>
            </PageContentWallpaper>


        );
    }
}

